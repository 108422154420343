import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss']
})
export class SelectDropdownComponent implements OnInit {
  isDropdownActive = false;
  selectedOption: any;
  @Input() options: any[];
  constructor() { }

  ngOnInit(): void {
  }

}
