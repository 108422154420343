export const environment = {
  production: false,
  apiURL: 'http://206.189.119.157:7011/api/',
  messagingApiURL: "http://206.189.119.157:8000/api/",
  formBuilderApiURL: "http://206.189.119.157:7011/api/",
  mobileServerAPIURL: 'http://159.65.148.36:7010/api/',
  stitchApiURL: 'https://webhooks.mongodb-stitch.com/api/client/v2.0/app/acumenwaste-sicpe/service/',
  chatApiUrl: "http://206.189.119.157:7010/api/",
firebaseConfig: {
    apiKey: "AIzaSyChGU-XD4rQQLjVwQJKqG6ApvYPKSpW5Hc",
    authDomain: "acumen-notification-ac0fe.firebaseapp.com",
    projectId: "acumen-notification-ac0fe",
    storageBucket: "acumen-notification-ac0fe.appspot.com",
    messagingSenderId: "153504309113",
    appId: "1:153504309113:web:da3c458987e333fcc9d9a8"
  }
}
