import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ExtrasService } from './extras.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, DialogService, DynamicDialogConfig, DynamicDialogRef, MessageService } from 'primeng';
import { JobService } from '../job/job-data.service';

@Component({
  selector: 'app-extras',
  templateUrl: './extras.component.html',
  styleUrls: ['./extras.component.scss']
})
export class ExtrasComponent implements OnInit {

  isUpdate = false;
  isView = false;
  isAcumen: any;
  companyID: any;
  showAdd = false;
  cols = [
    {
      header: "Name",
      field: "name",
    },
    {
      header: "Minimum Quantity",
      field: "defaultQuantity",
    },
    {
      header: "Cost",
      field: "defaultCost",
    },
  ];
  extrasData: any;
  adminUserList = [];
  showLoader = true;
  selectedExtras: any;
  extrasList = [];
  searchArr = [];
  constructor(private service: ExtrasService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private confirmDialogService: ConfirmationService,
    private jobService: JobService) { }

  ngOnInit(): void {
    this.fetchExtrasList();
  }

  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }

  createExtra(event) {
 
    if (this.showAdd == true) {
      this.showAdd = false;
      this.isView = false;
      this.extrasData = {};
      setTimeout((e) => {
        this.showAdd = true;
      }, 1000);
    } else {
      this.showAdd = true;
    }
    this.cdr.detectChanges();
  }

  updateExtras(event, data) {
    this.service.getExtrasDetails(data._id).subscribe((response) => {
      console.log("user  details", response.data);
      this.extrasData =  response.data;
      this.showAdd = true;
      console.log("user  details", this.extrasData);
      this.cdr.detectChanges();
    });
  }

  change(event, val) {
      this.service.updateExtras(val).subscribe(
        (response) => {
       
      },(error)=>{
        val.isActive = !val.isActive;
      });
  }

  deleteExtras(id) {
    this.confirmDialogService.confirm({
      message: "Are you sure that you want to delete the Vehicle Type?",
      accept: () => {
      
        this.service.deleteExtras(id).subscribe(
          (data: any) => {
            if (data) {
              this.messageService.add({
                severity: "success",
                summary: "Delete Extra",
                detail: "Extra deleted successfully",
              });
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Delete Extra",
                detail: data.error.message,
              });
            }
            this.reloadList('');
          },
          (err) => {
            console.log("error", err);
            this.messageService.add({
              severity: "error",
              summary: "Delete Extra",
                detail: "Failed to delete Extra",
            });
            
          }
        );
        //  });
      },
    });
  }

  reloadList(event) { 
    this.showLoader = true;
    this.fetchExtrasList();
  }

  callList(event) {
    this.showAdd = false;
    this.isView = false;
    this.extrasData = {};
    this.cdr.detectChanges();
    this.fetchExtrasList();
  }
  fetchExtrasList() {
    this.service.extrasList().subscribe((response) => {
      this.extrasList = response.data;
      this.showLoader = false;
    });
  }
}
