import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DisposalSitesRoutingModule } from './disposal-sites.routing.module';
import { DisposalSitesComponent } from './disposal-sites.component';
import { SharedModule } from '../../theme/shared/shared.module';
import { NgbCarouselModule, NgbProgressbarModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { AddEditDisposalSitesComponent } from './add-edit-disposal-sites/add-edit-disposal-sites.component';
import { AgmCoreModule } from '@agm/core';
import { MultiSelectModule } from 'primeng/multiselect';
import { Ng4GeoautocompleteModule } from '../../../modules/auto-complete/ng-4-geoautocomplete.module';
import { AddEditEWCCodeComponent } from './add-edit-ewc-code/add-edit-ewc-code.component';
import { AddEditPriceComponent } from './add-edit-price/add-edit-price.component';
import { ListEWCCodeComponent } from './list-ewc-code/list-ewc-code.component';
import { ListPriceComponent } from './list-price/list-price.component';
import { TooltipModule } from 'primeng/tooltip';
import { ImportingComponent } from './importing/importing.component'
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { NgxSpinnerModule } from "ngx-spinner";
import { LinkDisposalCustomerComponent } from './link-disposal-customer/link-disposal-customer.component';
import { DisposalExtrasListComponent } from './disposal-extras-list/disposal-extras-list.component';
import { DisposalExtrasEditComponent } from './disposal-extras-edit/disposal-extras-edit.component';
import { InputSwitchModule } from 'primeng';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  declarations: [DisposalSitesComponent, ImportingComponent, ListEWCCodeComponent, ListPriceComponent, LinkDisposalCustomerComponent, AddEditDisposalSitesComponent, AddEditPriceComponent, AddEditEWCCodeComponent, DisposalExtrasListComponent, DisposalExtrasEditComponent],
  imports: [
    CommonModule,
    DisposalSitesRoutingModule,
    SharedModule,
    TooltipModule,
    NgbProgressbarModule,
    NgbTabsetModule,
    NgbCarouselModule,
    DropdownModule,
    CheckboxModule,
    ToastModule,
    NgxSpinnerModule,
    ConfirmDialogModule,
    MessageModule,
    DynamicDialogModule,
    FileUploadModule,
    NgxSpinnerModule,
    MessagesModule,
    TableModule,
    Ng4GeoautocompleteModule.forRoot(),
    MultiSelectModule,

    NgbTabsetModule,
    TooltipModule,
    ToastModule,
    TableModule,
    InputSwitchModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    NgbCarouselModule,
    DropdownModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBcapYO6jKgeTykdEWCtzdJIHM6zK2LN1Q', // 'AIzaSyCV4EIRYyt8YOAQFgrjCfonCyJAuzRdrq4',
      libraries: ['places']
    }),
  ],
  exports: [AddEditDisposalSitesComponent, ImportingComponent, AddEditEWCCodeComponent, LinkDisposalCustomerComponent, AddEditPriceComponent, ListEWCCodeComponent, ListPriceComponent]
})
export class DisposalSitesModule {

}
