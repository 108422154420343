import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { DisposalSitesService } from "../disposal-sites.service";
import { ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { DialogService } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { JobService } from "../../job/job-data.service";
@Component({
  selector: "app-list-ewc-code",
  templateUrl: "./list-ewc-code.component.html",
  styleUrls: ["./list-ewc-code.component.scss"],
})
export class ListEWCCodeComponent implements OnInit {
  isUpdate = false;
  isView = false;
  isAcumen: any;
  companyID: any;
  showAdd = false;
  showLoader = true;
  data: any;
  selectedCatgeory: any;
  category: any;
  selectSubCategory = false;
  title: any;
  parentWasteType: any;
  wasteTypeData: any;
  ide: any;
  categoryList = [];
  cols = [
    // {
    //     header: 'Big change Id',
    //     field: 'Id'
    // },
    // {
    //     header: 'Company ID',
    //     field: 'ParentId'
    // },

    {
      header: "EWC Code",
      field: "ewc_code",
    },
    {
      header: "Description",
      field: "code_description",
    },
  ];
  searchArr = [];
  vehicleTypeData: any;
  adminUserList = [];
  selectedVehicle: any;
  vehicleTypeList = [];
  id: any;
  disposalData: any;
  constructor(
    private service: DisposalSitesService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private confirmDialogService: ConfirmationService,
    private router: Router,
    private jobService: JobService
  ) {}
  addCategory(event) {
    this.disposalData = this.data;
    this.category = false;
    this.showAdd = true;
    this.cdr.detectChanges();
  }
  callList(event) {
    console.log("call list");
    this.showAdd = false;
    this.isView = false;
    this.isUpdate = false;
    this.selectSubCategory = false;
    this.vehicleTypeData = {};
    this.cdr.detectChanges();
    this.fetchCategory();
  }
  closeEvent(event) {
    this.router.navigate(["disposalsites"]);
  }
  editCategory(event, data) {
    this.showAdd = true;
    this.category = false;
    this.isUpdate = true;
    this.selectSubCategory = true;
    this.title = data.code_description;
    (this.parentWasteType = data.ewc_code), data;
    this.disposalData = this.data;
    this.cdr.detectChanges();
  }
  removeCatgeory(event, data) {
    this.confirmDialogService.confirm({
      message: "Are you sure that you want to delete the Parent Waste Type?",
      accept: () => {
        //  this.service.deleteParentWasteType(data.Id).pipe(takeUntil(this.ngUnSubscribe)).subscribe((data: any) =>{
        if (data.status === 200) {
          this.messageService.add({
            severity: "success",
            summary: "Delete Parent Waste type",
            detail: "Parent Waste type deleted successfully",
          });
        }
        //   this.fetchUser();
        //} );
        this.updateDisposal(event, data);
      },
    });
  }
  updateDisposal(event: any, val: any) {
    let value: any = "";
    let data;
    const disposal = [];
    //  this.TipSitesForm.value.location = {lat:this.lat,lng:this.lng};
    value = this.data;
    if (value.disposal_type_details.parent_waste_category.length > 0) {
      value.disposal_type_details.parent_waste_category.forEach((element) => {
        if (element.ewc_code != val.ewc_code) {
          console.log("element", element, element.ewc_code, val.ewc_code);
          if (element.ewc_code) {
            const subArr = [];
            let val;
            if (element.sub_waste_category.length > 0) {
              element.sub_waste_category.forEach((e2) => {
                const subSubArr = [];
                if (e2.sub_sub_waste_category.length > 0) {
                  e2.sub_sub_waste_category.forEach((e3) => {
                    subSubArr.push(e3.ewc_code);
                  });
                }
                val = {
                  ewc_code: e2.ewc_code,
                  sub_sub_waste_category: subSubArr,
                };
                subArr.push(val);
              });
            }
            data = { ewc_code: element.ewc_code, sub_waste_category: subArr };
          } else {
            data = { ewc_code: element, sub_waste_category: [] };
          }
          disposal.push(data);
        }
      });
      value.disposal_type_details.parent_waste_category = disposal;
    }
    console.log("value", value);
    this.service.updateDisposalLocation(value).subscribe((data: any) => {
      if (data.status === 200) {
        this.fetchCategory();
      } else if (data.status === 500) {
        this.messageService.add({
          severity: "error",
          // summary: 'Update Disposal site',
          detail: data.error.message.errmsg,
        });
      } else {
        this.messageService.add({
          severity: "error",
          //  summary: 'Update Disposal site',
          // detail: 'Failed to quotes the Tip site details'
          detail: "Unable to update Tip site.Please try again.",
        });
      }
    });
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");
    this.fetchCategory();
    if (this.cols.length > 0) {
      this.cols.forEach((element) => {
        this.searchArr.push(element.field);
      });
    }
  }
  fetchCategory() {
    this.service.getDisposalDetails(this.id).subscribe((data: any) => {
     
      this.categoryList = data.data.disposal_type_details.parent_waste_category;
      this.showLoader = false;
      this.data = data.data;
    });
  }
  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }
}
