<div class="row">
    <!-- tabs card start -->
    <div class="col-sm-12">
      <app-card moduleName="Extras" module="extras" 
        cardClass="table-card" blockClass="p-0" (addExtra)="createExtra($event)" (extrasReload)="reloadList($event)" >
      
        <!-- <perfect-scrollbar [style.max-height]="'415px'"> -->
      
        <p-table #dt [value]="extrasList" [(selection)]="selectedExtras" dataKey="_id"
          styleClass="ui-table-customers" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[10, 25, 50, 100]" [paginator]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
          [globalFilterFields]="searchArr">
          <ng-template pTemplate="caption">
            <div class="ui-table-globalfilter-container">
              <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="Global Search" />
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th *ngFor="let col of cols" [pSortableColumn]="col.field">
                <b>{{ col.header }}</b>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
  
              <!-- <th>Edit</th> -->
              <!-- <th>View</th>
                      <th>Delete</th> -->
              <th></th>
            </tr>
            <tr>
              <th *ngFor="let col of cols" [ngSwitch]="col.field">
                <input pInputText type="text" class="ui-inputtext"
                  (input)="dt.filter($event.target.value, col.field, 'contains')" />
              </th>
              <!-- <th></th> -->
              <!-- <th></th> -->
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-extra>
            <tr *ngIf="!showLoader">
              <td *ngFor="let col of cols" >
                {{ extra[col.field] }}
              </td>
  
              <td *ngIf="checkPermissions('edit', 'Extras')">
                <span>
                  <p-checkbox name="isActive" ngDefaultControl [(ngModel)]="extra.isActive"
                    class="map-checkbox text-allign-btns" (onChange)="change($event, extra)" label="Active"
                    binary="false"></p-checkbox>
                </span>
              </td>
              <td>
              
                <span  pTooltip="Click to edit an extra" tooltipPosition="top"
                  class="mob_icon style-toggler delete_icon" (click)="updateExtras($event,extra)"  *ngIf="checkPermissions('edit', 'Extras')">
                  <i class="fa fa-edit fa-2x"></i>
                </span>
                <span  pTooltip="Click to delete an extra" tooltipPosition="top" (click)="deleteExtras(extra._id)" 
                  class="style-toggler mob_icon delete_icon" *ngIf="checkPermissions('delete', 'Extras')">
                  <i class="fa fa-trash fa-2x"></i>
                </span>
                <!-- <span *ngIf="!car.delete">
                            -
                          </span> -->
              </td>
            </tr>
          </ng-template>
          <ng-template class="noRecords" *ngIf="extrasList.length == 0" pTemplate="emptymessage">
            <tr class="noRecords" *ngIf="!showLoader">
              <td [attr.colspan]="cols.length + 1">
                <!-- <div class="my-center-text"> -->
                No Records to display
                <!-- </div> -->
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- </perfect-scrollbar> -->
        <div class="loader" *ngIf="showLoader"></div>
      </app-card>
    </div>
    <!-- tabs card end -->
  </div>
  
  <div *ngIf="showAdd">
    <app-add-exit-extras [isView]="isView" [data]="extrasData"
      (closeEvents)="callList($event)"></app-add-exit-extras>
   
  </div>
