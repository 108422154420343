import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ExtrasService {

  constructor(private http : HttpClient) { }

  extrasList() {
    return this.http.get(environment.apiURL + 'extras/list').pipe(
        tap((response: any) => {
            return response;
        })
    );
}

addExtras(data) {
    return this.http.post(environment.apiURL + 'extras/add', data).pipe(
        tap((response: any) => {
            return response;
        })
    );
}

getExtrasDetails(id) {
    return this.http.post(environment.apiURL + 'extras/details', {id:id}).pipe(
        tap((response: any) => {
            return response;
        })
    );
}
updateExtras(data) {
    return this.http.post(environment.apiURL + 'extras/update', data).pipe(
        tap((response: any) => {
            return response;
        })
    );  }

    updateDisposalExtras(data) {
        return this.http.post(environment.apiURL + 'extras/disposal-extras', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }

    deleteExtras(data) {
      
        return this.http.post(environment.apiURL + 'extras/delete', {id:data}).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }

}
