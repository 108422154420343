<div id="container">
    <div id="button" (click)="isDropdownActive = isDropdownActive === true ? false: true">
        {{ selectedOption?.name ? selectedOption.name : (options && options.length ? options[0].name : "Select an option") }}
        <div id="dropdown" [ngClass]="{ active: isDropdownActive }">
            <ul>
                <li *ngFor="let option of options">
                    <a (click)="selectedOption = option;
                    isDropdownActive = false;
                    $event.stopImmediatePropagation()
                    ">{{ option.name }}</a>
                </li>
            </ul>
        </div>
    </div>
</div>
 