import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExtrasRoutingModule } from './extras-routing.module';
import { AddExitExtrasComponent } from './add-exit-extras/add-exit-extras.component';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { ConfirmDialogModule, DropdownModule, DynamicDialogModule, InputSwitchModule, MessageModule, MessagesModule, TableModule, ToastModule, TooltipModule } from 'primeng';
import { NgbCarouselModule, NgbProgressbarModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    AddExitExtrasComponent
  ],
  imports: [
    CommonModule,
    ExtrasRoutingModule,
    SharedModule,
    NgbProgressbarModule,
    NgbTabsetModule,
    TooltipModule,
    ToastModule,
    TableModule,
    InputSwitchModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    NgbCarouselModule,
    DropdownModule,
  ],
  exports:[AddExitExtrasComponent]
})
export class ExtrasModule { }
