import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ExtrasService } from '../../extras/extras.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, DialogService, DynamicDialogConfig, DynamicDialogRef, MessageService} from 'primeng';
import { JobService } from '../../job/job-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DisposalSitesService } from '../disposal-sites.service';


@Component({
  selector: 'app-disposal-extras-list',
  templateUrl: './disposal-extras-list.component.html',
  styleUrls: ['./disposal-extras-list.component.scss']
})
export class DisposalExtrasListComponent implements OnInit {

  isUpdate = false;
  isView = false;
  data
  id
  isAcumen: any;
  companyID: any;
  showAdd = false;
  cols = [
    {
      header: "Name",
      field: "name",
    },
    {
      header: "Minimum Quantity",
      field: "defaultQuantity",
    },
    {
      header: "Cost",
      field: "defaultCost",
    },
  ];
  filtredList=[];
  currentSlectedExtras=[];
  extrasData: any;
  showLoader = true;
  selectedExtras: any;
  extrasList = [];
  searchArr = [];
  constructor(private service: ExtrasService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private disposalService: DisposalSitesService,
    private messageService: MessageService,
    private route:ActivatedRoute,
    private confirmDialogService: ConfirmationService,
    private jobService: JobService,   private router: Router,) { }
  

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get("id");
    this.getDisposalDetails();
   
  }

  closeEvent(event) {
    this.router.navigate(["disposalsites"]);
  }

  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }

  createExtra(event) {
    if (this.showAdd == true) {
      this.showAdd = false;
      this.isView = false;
      this.extrasData = {};
      setTimeout((e) => {
        this.showAdd = true;
      }, 1000);
    } else {
      this.showAdd = true;
    }
    this.cdr.detectChanges();
  }

  updateExtras(event, data) {
    this.service.getExtrasDetails(data._id).subscribe((response) => {
      response.data.defaultQuantity = data.defaultQuantity.toString();
      response.data.defaultCost = data.defaultCost.toString();
    this.extrasData = {"id": this.data._id,"extras":[response.data],"isUpdate":true};
      this.showAdd  = true;
      this.cdr.detectChanges();
    });
  }

  change(event, val) {
    let  extras = this.filtredList.filter((ele)=>{
      if(ele._id == val._id){
        ele.isActive = val.isActive;
        return ele;
      }
    });
      this.service.updateDisposalExtras({"id": this.data._id,"extras":extras}).subscribe(
        (response) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Extras Updated Successfully' });
      },(error)=>{
        val.isActive = !val.isActive;
      });
  }



  reloadList(event) { 
    this.showLoader = true;
    this.fetchExtrasList();
  }

  callList(event) {
    this.showAdd = false;
    this.isView = false;
    this.extrasData = {};
    this.cdr.detectChanges();
    this.getDisposalDetails();
  }
  
  fetchExtrasList() {
    this.extrasList = [];
    this.currentSlectedExtras = []; 
    this.filtredList = [];
    this.service.extrasList().subscribe((response) => {
      this.extrasList = response.data;
      this.currentSlectedExtras = this.data.disposal_type_details.extras;
      this.extrasList.forEach(element => {
        let found = false;
        this.currentSlectedExtras.forEach((ele)=>{
          if(element._id == ele._id){
            ele.isActive = true;
            found = true;
            this.filtredList.push(ele);
          }
        })
        if(!found){
          element.isActive = false;
          this.filtredList.push(element);
        }
      });
     
      this.showLoader = false;
    })
  }
  getDisposalDetails() {
    this.disposalService.getDisposalDetails(this.id).subscribe((data: any) => {
      this.showLoader = false;
      this.data = data.data;
      this.fetchExtrasList();

    });
  }

 
}
